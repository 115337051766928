import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from './util/styled';

const HowItWorkContainer = styled.div`
	padding: 20px 0 0 0;
	background: #f5f9ff;

	@media (min-width: 1200px) {
		padding-bottom: 40px;
	}
`;

const TitleContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	grid-column-gap: 3rem;
	align-content: center;
	align-items: center;
	@media (min-width: 1200px) {
		padding: 0;
	}
`;

const TitleLine = styled.div`
	height: 2px;
	width: 100%;
	border-bottom: 2px solid #b9d5ec;
`;

const CenterContain = styled.div`
	text-align: center;
	color: #333;
	font-size: 1rem;
	font-weight: bold;
	padding: 20px 10%;
`;

const TableContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	justify-items: center;
	padding: 30px 20px !important;
	margin: 0 auto;

	@media (min-width: 600px) {
		width: 600px;
	}
`;

const AgileTable = styled.table`
	background: #fff;
	border-radius: 0 0 12px 12px;
	font-size: 0.9rem;
	box-shadow: 0 2px 4px 0 rgba(66, 118, 146, .2), 0 10px 40px 0 rgba(66, 118, 146, .1);
	color: #333;
	font-weight: bold;
	text-align: center;
	border-collapse: collapse;

	@media (min-width: 800px) {
		text-align: center;
	}

	caption {
		background: #20bdff;
		color: #fff;
		padding: 10px 0;
		border-radius: 12px 12px 0 0;
		font-size: 1.1rem;
	}

	th {
		text-align: center;
        width: 100%;
	}

	td {
		text-align: center;
	}

	.td-detail {
		text-align: left;
		width: 75%;
	}
`;

const AgileTalent = () => {
	return (
		<HowItWorkContainer>
			<TitleContainer>
				<TitleLine />
				<SectionTitle title="什麼是機動人才" />
				<TitleLine />
			</TitleContainer>
			<CenterContain>
				<p>
					機動人才(Agile Talent)是作為企業的策略強化而聘用的人，能清楚分類他們的技能如何強化你的策略<br />他們會以項目形式、兼職形式或其他不同於全職員工的方式加入
				</p>
			</CenterContain>
			<TableContainer>
				<AgileTable>
					<caption>機動人才帶給您</caption>
					<tbody>
						<tr>
							<td>專業</td>
							<td className="td-detail">擁獨特技能、經驗、風格</td>
						</tr>
						<tr>
							<td>速度</td>
							<td className="td-detail">立即獲得新技術，而公司不用投資去學習</td>
						</tr>
						<tr>
							<td>成本</td>
							<td className="td-detail">因專業所以更有效率完成</td>
						</tr>
						<tr>
							<td>彈性</td>
							<td className="td-detail">切換技能及知識應付突如其來的轉變</td>
						</tr>
						<tr>
							<td>知識</td>
							<td className="td-detail">利用新技術知識給予更多策略方向</td>
						</tr>
					</tbody>
				</AgileTable>
			</TableContainer>
		</HowItWorkContainer>
	);
};

export default AgileTalent;
