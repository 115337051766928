import React from 'react';
import styled from 'styled-components';
// import { TalentPoolBtnWhite } from './util/talentPoolBtn'

const Container = styled.div`
	background: linear-gradient(
		-225deg,
		#5bd5f3,
		rgb(27, 172, 229) 24%,
		rgb(27, 172, 229) 50%,
		rgb(27, 172, 229) 75%,
		#b9e9f1
	);
	display: flex;
	flex-direction: column;
	padding: 25px 25px 50px 25px;

	@media (min-width: 700px) {
		max-width: 100%;
		margin: 0 auto;
		padding: 80px;
	}
`;

const AlignLeft = styled.div`
	justify-items: center;
	text-align: left;
	align-items: left;
`;

const H2 = styled.h2`
	padding-bottom: 10px;
	line-height: 44px;
	font-family: Raleway;
	font-weight: 700;
	margin: 0;
	text-align: center;
	font-size: 1.5rem;

	@media (min-width: 1200px) {
		max-width: 100%;
		margin: 0 auto;
		text-align: center;
	}
`;

const P = styled.p`
	margin: 10px;
    /* font-family: Nunito; */
    font-weight: 400;
    line-height: 24px;
	display: block;
    font-size: 0.9em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 1em;
    margin-inline-end: 1em;
    font-weight: bold;

	@media (min-width: 1200px) {
		margin: 10px 0 20px
	}
`;

const AboutUs = () => {
	return (
		<Container id="about">
			<AlignLeft>
				<H2>關於我們</H2>
				<P>
					成立於2017年，起初由Telegram群組集結自由工作者，互相尋找合作機會一起完成項目， 後來發現每個自由工作者擁有各自的技能，他們組成小團隊可以完成更大型的項目，
					故此建立Perhelp團結自由工作者，大家一起組成小團隊，每夥星星互相連接、互補技能，發揮各自的小宇宙，為社會提供更強大更創新的創造力。
				</P>
			</AlignLeft>
				{/* <TalentPoolBtnWhite to="/" btnText="與朋友一起加入機動人才庫" /> */}
		</Container>
	);
};

export default AboutUs;
