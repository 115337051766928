import React from 'react';
import PropTypes from 'prop-types';
// import 'typeface-noto-sans-tc';

import Header from './header';
import Footer from './footer';
import styled from 'styled-components';
import { seoMetadata } from '../components/util/seo.metadata';

const Main = styled.main`
	width: 100%;
	overflow: hidden;
	color: #fff;
	margin-top: 4rem;
`;

const Layout = ({ children, header, footer }) => {

	return (
		<div
			style={{
				display: 'flex',
				minHeight: '100vh',
				flexDirection: 'column'
			}}
		>
			{header || <Header />}
			<Main>{children}</Main>
			{footer || <Footer social={seoMetadata.social} contact={seoMetadata.contact} />}
		</div>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
