import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SectionTitle } from './util/styled';
import LazyImg from './util/lazyImg';
import sendTask from '../img/send-task.png'
import freeContact from '../img/free-contact.png'
import skillSearch from '../img/skillsearch.png'

const HowItWorkContainer = styled.div`
	padding-top: 20px;
	background: rgb(255, 255, 255);

	@media (min-width: 1200px) {
		padding-bottom: 60px;
	}
`;

const H3 = styled.h3`
	text-align: center;
	line-height: 44px;
	display: block;
	font-size: 1.5rem;
	color: #333;
	font-family: Raleway;
	font-weight: 700;
	padding-top: 20px;

	@media (min-width: 1200px) {
		padding: 80px 0 40px;
	}
`;

const CardSection = styled.div`
	padding: 10px 20px 40px;

	div {
		background: transparent;
		padding: 0px;
	}
`;

const HowItWorkSection = styled.div`
	margin-bottom: 40px;
	display: block;

	@media (min-width: 1200px) {
		margin-bottom: 60px;
	}
`;

const TwoColumns = styled.div`
	padding: 20px;
	@media (min-width: 1200px) {
		padding: 0;
	}
`;

const Inner = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 20px;
	justify-content: space-between;
	max-width: 1180px;
	margin: 0 auto;
	column-gap: 80px;
	grid-auto-rows: auto;
	background-color: rgb(255, 255, 255);

	@media (min-width: 1200px) {
		grid-template-columns: repeat(2, 1fr);
		padding: 0;
	}
`;

const InnerReverse = styled.div`
	column-gap: 80px;
	grid-auto-rows: auto;
	background-color: rgb(255, 255, 255);
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 20px;
	justify-content: space-between;
	max-width: 1180px;
	margin: 0 auto;

	div :first-child {
		grid-row: 2;
	}

	@media (min-width: 1200px) {
		grid-template-columns: repeat(2, 1fr);
		padding: 0;

		div :first-child {
			grid-row: 1;
		}
	}
`;

const H4 = styled.h4`
	margin-top: 0;
	margin-bottom: 10px;
	display: block;
	align-items: center;
	font-size: 1.4rem;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 34px;
	font-family: Raleway;
	color: #333;
	text-align: center;

	@media (min-width: 1200px) {
		text-align: left;
		margin: 20px 0;
	}
`;

const P = styled.p`
	margin-top: 0;
	text-align: center;
	font-family: Nunito;
	font-weight: 400;
	line-height: 24px;
	display: block;
	font-size: 0.9em;
	margin-block-start: 1.67em;
	margin-block-end: 1.67em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
	color: #333;

	@media (min-width: 1200px) {
		text-align: left;
		margin: 20px 0;
	}
`;

const ImageContanier = styled.div`
	@media (min-width: 1200px) {
		width: 100%;
		height: 300px;
	}
`;

const Card = styled.div`
	min-height: 220px;
	border-radius: 8px;
	box-shadow: 0 2px 3px 0 rgba(66, 118, 146, .1), 0 8px 31px 0 rgba(66, 118, 146, .1);
	display: grid;
	grid-template-columns: 1fr;
	justify-items: center;
	padding: 30px 10px !important;
	background: linear-gradient(-225deg, #5bd5f3, #20bdff 24%, #20bdff 50%, #20bdff 75%, #b9e9f1) !important;
`;

const CardTitle = styled.h4`
	color: #fff;
	font-size: 1.5rem;
	font-weight: 700;
	text-align: center;
	margin: 0;
`;
const CardContent = styled.p`
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	margin: 0;
`;

const TitleContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	grid-column-gap: 3rem;
	align-content: center;
	align-items: center;
	@media (min-width: 1200px) {
		padding: 0;
	}
`;

const TitleLine = styled.div`
	height: 2px;
	width: 100%;
	border-bottom: 2px solid #b9d5ec;
`;

const HowItWork = () => {
	return (
		<HowItWorkContainer id="sme">
			<TitleContainer>
				<TitleLine />
				<SectionTitle title="如何尋找人才" />
				<TitleLine />
			</TitleContainer>
			<CardSection>
				<div>
					<TwoColumns>
						<Inner>
							<a href="#on-demand" style={{ color: '#fff', textDecoration: 'none' }}>
								<Card>
									<FontAwesomeIcon className="contact-icons" icon="hand-pointer" size="3x" />
									<CardTitle>自選人才</CardTitle>
									<CardContent>免費使用機動人才庫，自由聯繫合適的專才</CardContent>
								</Card>
							</a>
							<a href="#aglieManege" style={{ color: '#fff', textDecoration: 'none' }}>
								<Card>
									<FontAwesomeIcon className="contact-icons" icon="hands-helping" size="3x" />
									<CardTitle>機動人才管理服務</CardTitle>
									<CardContent>分析項目需要技能，助你篩選最合適人才，慳時慳力保質素</CardContent>
								</Card>
							</a>
						</Inner>
					</TwoColumns>
				</div>
			</CardSection>
			<H3 id="on-demand">一、自選人才</H3>
			<HowItWorkSection>
				<TwoColumns>
					<Inner>
						<div>
							<H4>技能/服務關鍵字</H4>
							<P>
								以技能找出人才、以服務找出團隊<br />例如:您想找人協助網站提升搜尋器排名，除了搜尋"數碼營銷"外，還可以以"SEO"等技能關鍵字搜尋
							</P>
						</div>
						<ImageContanier>
							{/* <Image filename="skillsearch.png" alt="use skills or services keyword to saerch the agile talent" /> */}
							<LazyImg 
								src={skillSearch} 
								alt="use skills or services keyword to saerch the agile talent"
								offset={100}
								once
							/>
						</ImageContanier>
					</Inner>
				</TwoColumns>
			</HowItWorkSection>
			<HowItWorkSection>
				<TwoColumns>
					<InnerReverse>
						<ImageContanier>
							{/* <Image filename="free-contact.png" alt="contact agile talent without restrictions" /> */}
							<LazyImg 
								src={freeContact} 
								alt="contact agile talent without restrictions" 
								offset={100}
								once
							/>
						</ImageContanier>
						<div>
							<H4>自由聯絡</H4>
							<P>Perhelp不會收取任何中介費，您只需要到人才頁面按下他的聯絡方法，雙方便可自由聯絡，完全跳過中介，成為好友後更可於app內實時對話</P>
						</div>
					</InnerReverse>
				</TwoColumns>
			</HowItWorkSection>
			<HowItWorkSection>
				<TwoColumns>
					<Inner>
						<div>
							<H4>群體通知</H4>
							<P>不想一個一個找尋的話，便可使用我們發出任務功能，填寫好任務內容、及尋找的技能後，便可群發給擁有相應技能的人才，等候他們聯絡你</P>
						</div>
						<ImageContanier>
							{/* <Image filename="send-task.png" alt="send a task to specify right skill talent" loading="lazy" /> */}
							<LazyImg 
								src={sendTask} 
								alt="send a task to specify right skill talent" 
								offset={100}
								once
							/>
						</ImageContanier>
					</Inner>
				</TwoColumns>
			</HowItWorkSection>
		</HowItWorkContainer>
	);
};

export default HowItWork;
