import React from 'react';
import styled from 'styled-components';
import LazyImg from './util/lazyImg';
import partners from '../img/partners2.png'

const PartnersContainer = styled.div`
	background: #fff;
	color: #333;
	padding: 25px;

	@media (min-width: 1200px) {
		max-width: 100%;
		margin: 0 auto;
		padding: 40px 0;
	}
`;

const H2 = styled.h2`
	padding: 10px;
	line-height: 44px;
	font-family: Raleway;
	font-weight: 700;
	margin: 0;
	text-align: center;
	font-size: 1.5rem;

	@media (min-width: 1200px) {
		max-width: 100%;
		margin: 0 auto;
		text-align: center;
	}
`;

const ImageContainer = styled.div`
	opacity: 1;
	width: 40%;
	margin: 0 auto;

	@media (max-width: 700px) {
		width: 100%;
	}


`;

const Partners = () => {
	return (
		<PartnersContainer>
			<H2>支持單位</H2>
			<ImageContainer>
				{/* <Image filename="partners1.png" alt="HAB, YDC and Cyberport supporting Perhelp to startup" loading="lazy" /> */}
				<LazyImg 
					src={partners} 
					alt="HAB, YDC and Cyberport supporting Perhelp to startup" 
					offset={100}
					once
				/>
			</ImageContainer>
		</PartnersContainer>
	);
};

export default Partners;
