import React from "react"
import Helmet from "react-helmet"
import {seoMetadata} from './util/seo.metadata'

function SEO(props) {

  const metaDescription = seoMetadata.description;
  const title = seoMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang: `zh-Hant`
      }}
      title={props.title}
      titleTemplate={`%s | ${seoMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: props.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: seoMetadata.author,
        },
        {
          name: `twitter:title`,
          content: props.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  )
}

export default SEO
