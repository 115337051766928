import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BenefitContainer = styled.div`
	background: #f5f9ff;
	padding: 50px 25px 0 25px;
	color: #333;
	margin-top: -50px;
	/* display:  */
	text-align: center;
`;

const H4 = styled.h4`
	line-height: 44px;
	font-family: Raleway;
	font-weight: 700;
	font-size: 1.3rem;

	@media (min-width: 1200px) {
		text-align: center;
		padding: 30px 0 30px;
		font-size: 1.4rem;
	}
`;

const TwoCoulumns = styled.div`
	padding: 20px;
	font-display: swap;
	box-sizing: border-box;

	@media (min-width: 1200px) {
		padding: 20px 20px 40px;
	}
`;

const TwoCoulumnsInner = styled.div`
	column-gap: 20px;
	grid-auto-rows: auto;
	background-color: transparent;
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 20px;
	justify-content: space-between;
	max-width: 1180px;
	margin: 0 auto;

	@media (min-width: 1200px) {
		grid-template-columns: repeat(2, 1fr);
		padding: 0;
	}
`;

const MiniIcon = styled.div`
	display: inline flex;
	/* grid-template-columns: 20px 1fr; */
	/* justify-content: flex-start; */
	text-align: center;
`;

const H5 = styled.h5`
	/* text-align: left; */
	text-transform: capitalize;
	margin: 0 0 5px 10px !important;
	line-height: 20px;
	font-family: Nunito;
	font-size: 1.1rem;
	font-weight: 700;
	display: block;
	margin-block-start: 1.33em;
	margin-block-end: 1.33em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
`;

const P = styled.p`
	margin: 10px 0;
	font-family: Nunito;
	font-weight: 400;
	line-height: 24px;
	display: block;
	font-size: 0.9em;
	margin-block-start: 1.67em;
	margin-block-end: 1.67em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;

	@media (min-width: 1200px) {
		margin: 10px 0 20px;
	}
`;

const Services = styled.div`
	background-color: #fff;
	border-radius: 12px;
	overflow: hidden;
	position: relative;
	padding-bottom: 20px;
	box-shadow: 0 2px 4px 0 rgba(66, 118, 146, .1), 0 10px 40px 0 rgba(66, 118, 146, .1);
	width: 300px;
	margin: 0 auto 20px;

	@media (min-width: 700px) {
		width: 50%;
	}
`;

const SerLabel = styled.div`
	background: #1ec4e9;
	width: 140px;
	height: 40px;
	border-radius: 4px;
	padding: 8px;

	p {
		color: #fff;
		font-size: 20px;
		font-weight: 700;
		text-align: center;
		line-height: 24px;
		text-transform: uppercase;
		font-family: Nunito;
	}
`;

const SerTop = styled.div`
	border-bottom: 2px solid rgba(30, 196, 233, .2);
	color: #556f7d;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 24px;
	/* padding: 20px 0 30px; */
	margin-bottom: 40px;
`;

const CardTitle = styled.h3`
	text-align: center;
	line-height: 44px;
	display: block;
	font-size: 1.5rem;
	color: #333;
	padding-top: 20px;
	font-family: Raleway;

	@media (min-width: 1200px) {
		padding: 20px 0;
	}
`;

const CardList = styled.div`
	margin-left: 1.45rem;
	position: relative;

	div {
		display: grid;
		grid-template-columns: 50px 1fr;
		width: 300px;
		margin: 0 auto;

		span {
			margin-bottom: 15px;
			display: flex;
			align-items: flex-start;
		}
	}

	@media (min-width: 1200px) {
		div > span {
			text-align: center;
		}
	}
`;

const TitleDesc = styled.p`
	font-size: 1rem;
	margin: 0 0.5rem 0.5rem 0.5rem;
`

const Benefit = () => {
	const ServiceContent = [ '專人負責貴公司帳戶', '分析及建議項目所需技能', '預先篩選專才', '更多的線下人專才選擇', '直接與專才溝通', '保障期', '跟進項目進度', '擔保交易' ];

	return (
		<BenefitContainer id="aglieManege">
			<Services>
				{/* <div style={{ padding: '20px' }}> */}
					<SerLabel>
						<p>免費開戶</p>
					</SerLabel>
					<SerTop>
						<CardTitle>二、機動人才管理服務</CardTitle>
						<TitleDesc>慳時、慳力，質素更高，等於多了一個專業的項目助理</TitleDesc>
					</SerTop>
					<CardList>
						{ServiceContent.map((detail, index) => {
							return (
								<div key={index}>
									<FontAwesomeIcon
										className="card-icon"
										icon="check-circle"
										style={{ color: 'green', marginTop: '4px' }}
									/>
									<span>{detail}</span>
								</div>
							);
						})}
					</CardList>
				{/* </div> */}
			</Services>
			<H4>優勢</H4>
			<TwoCoulumns>
				<TwoCoulumnsInner>
					<div>
						<MiniIcon>
							<FontAwesomeIcon className="benifits-icons" icon="palette" style={{ color: 'purple' }} />
							<H5>不同風格</H5>
						</MiniIcon>
						<P>根據項目選擇不同技能、風格的人才</P>
					</div>
					<div>
						<MiniIcon>
							<FontAwesomeIcon
								className="benifits-icons"
								icon="hands-helping"
								style={{ color: 'Tomato' }}
							/>
							<H5>團隊協作</H5>
						</MiniIcon>
						<P>比個人工作擁有更全面的技能組合</P>
					</div>
					<div>
						<MiniIcon>
							<FontAwesomeIcon
								className="benifits-icons"
								icon="asterisk"
								style={{ color: 'DarkGoldenRod' }}
							/>
							<H5>分散風險</H5>
						</MiniIcon>
						<P>細分項目工作、分散爛尾分險</P>
					</div>
					<div>
						<MiniIcon>
							<FontAwesomeIcon className="benifits-icons" icon="tasks" style={{ color: 'Teal' }} />
							<H5>跟進進度</H5>
						</MiniIcon>
						<P>與客戶一起管理項目，成為雙方的橋樑</P>
					</div>
					<div>
						<MiniIcon>
							<FontAwesomeIcon
								className="benifits-icons"
								icon="exchange-alt"
								style={{ color: 'Salmon' }}
							/>
							<H5>更換人才</H5>
						</MiniIcon>
						<P>保證期內可免費更換人才</P>
					</div>
					<div>
						<MiniIcon>
							<FontAwesomeIcon
								className="benifits-icons"
								icon="umbrella"
								style={{ color: 'DarkSeaGreen' }}
							/>
							<H5>擔保交易</H5>
						</MiniIcon>
						<P>代保管報酬，按進度發放</P>
					</div>
				</TwoCoulumnsInner>
			</TwoCoulumns>
		</BenefitContainer>
	);
};

export default Benefit;
