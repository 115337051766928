// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { TalentPoolBtn } from './util/talentPoolBtn';
import { StyledImg } from './util/styled';
import logo from '../img/logo.png';

const StyledHeader = styled.header`
	/* background: rebeccapurple; */
	background: rgba(255, 255, 255);
	/* margin-bottom: 1.45rem; */
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
`;

const Container = styled.div`
	width: 80px;
	align-items: center;
	margin-left: 2rem;
	margin-right: 2rem;
	padding-top: 5px;

	@media screen and (max-width: 900px) {
		width: 75px;
		margin-left: 1.5rem;
		padding-top: 3px;
	}

	@media screen and (max-width: 600px) {
		width: 60px;
		margin-left: 1.5rem;
		padding-top: 3px;
	}
`;

const Nav = styled.nav`
	flex: 1 1;
	font-size: 1rem;
	transition: transform 0.3s ease-in-out;
	margin-right: 0.9rem;

	ul {
		display: flex;
		justify-content: flex-end;
		margin: 0 .85rem;
		line-height: 50px;

		.hover-list a {
			padding: .85rem 0.2rem;
		}

		.hover-list {
			&:hover {
				background-color: rgba(0, 0, 0, 0.6);
				a {
					color: #5bd5f3;
				}
			}
		}

		li {
			margin: 0;
			list-style: none;
			padding: .85rem;
			position: relative;
			align-items: flex-start;

			a {
				color: #333;
				text-decoration: none;
				font-weight: bold;
			}
		}
	}
	@media screen and (max-width: 900px) {
		display: none;
	}
`;

const MobNav = styled.nav`
	flex: 1 1;
	font-size: 1rem;
	transition: transform 0.3s ease-in-out;
	/* background: rgba(255, 255, 255, 0.9); */
	margin-bottom: 1.45rem;
	display: ${({ open }) => (open ? 'block' : 'none')};
	padding-bottom: 1em;
	font-weight: 500;

	width: 100%;
	height: calc(100vh - 60px);
	background: #fff;
	margin-top: 60px;
	padding-top: 30px;
	position: fixed;
	z-index: 100;

	ul {
		display: flex;
		margin: 0 .85rem;
		flex-direction: column;
		align-items: center;

		.hover-list a {
			padding: 0 5rem;
		}
		.hover-list {
			&:hover {
				background: rgba(0, 0, 0, 0.6);

				a {
					color: #5bd5f3;
				}
			}
		}

		li {
			margin: 0;
			list-style: none;
			padding: 1rem;
			position: relative;
			font-size: 1rem;

			a {
				color: #333;
				text-decoration: none;
				font-weight: bold;
			}
		}
	}

	@media screen and (min-width: 901px) {
		display: ${({ open }) => (open ? 'none' : 'none')};
	}
`;

const BarsButton = styled.button`
	position: absolute;
	display: none;
	right: 10px;
	background: none;
	border: none;
	color: #000;

	@media screen and (max-width: 900px) {
		font-size: 1.5rem;
		text-align: center;
		display: block;
		right: 20px;
	}
`;

const Header = () => {
	const [ open, setOpen ] = useState(false);
	return (
		<div id="header">
			<StyledHeader>
				<Container>
					<a href="/#header">
						<StyledImg
							src={logo}
							alt="Perhelp logo for agile talent in Hong Kong"
						/>
					</a>
				</Container>
				<Nav>
					<ul>
						<li className="hover-list">
							<a href="/#header">首頁</a>
						</li>
						<li className="hover-list">
							<a href="/#sme">尋找人才</a>
						</li>
						{/* <li className="hover-list">
							<a href="/#agileTalent">成為機動人才</a>
						</li> */}
						<li className="hover-list">
							<a href="/#about">關於我們</a>
						</li>
						{/* <li className="hover-list">
							<Link to="/">Blog</Link>
						</li> */}
						{/* <li>
							<TalentPoolBtn to="/login" btnText="機動人才庫" />
						</li> */}
					</ul>
				</Nav>
				<BarsButton aria-label="Bars navigation button for mobile on landing page" open={open} onClick={() => setOpen(!open)}>
					<FontAwesomeIcon icon="bars" />
				</BarsButton>
			</StyledHeader>
			<MobNav open={open}>
				<ul>
					<li className="hover-list">
						<a href="/#header" onClick={() => setOpen(!open)}>
							首頁
						</a>
					</li>
					<li className="hover-list">
						<a href="/#sme" onClick={() => setOpen(!open)}>
							尋找人才
						</a>
					</li>
					{/* <li className="hover-list">
						<a href="/#agileTalent" onClick={() => setOpen(!open)}>
							成為機動人才
						</a>
					</li> */}
					<li className="hover-list">
						<a href="/#about" onClick={() => setOpen(!open)}>
							關於我們
						</a>
					</li>
					{/* <li className="hover-list">
						<Link to="/" onClick={() => setOpen(!open)}>
							Blog
						</Link>
					</li> */}
					{/* <li>
						<TalentPoolBtn btnText="機動人才庫" onClick={() => setOpen(!open)} />
					</li> */}
				</ul>
			</MobNav>
		</div>
	);
};

Header.propTypes = {
	siteTitle: PropTypes.string
};

Header.defaultProps = {
	siteTitle: ``
};

export default Header;
