// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

import SEO from './components/seo';
import styled from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faAtom, faCompressArrowsAlt, faHandsHelping, faEnvelope, faCheckCircle, faPalette, faAsterisk, faTasks, faExchangeAlt, faUmbrella } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import HowItWork from './components/howItWork';
import Benefit from './components/benefit';
import Partners from './components/partners';
import AboutUs from './components/about';
import AgileTalent from './components/agileTalent';
// import JoinAgileTalent from '../../components/landing/joinAgileTalent';
import Layout from './components/layout'
import './App.css';
library.add(fab, faBars, faAtom, faCompressArrowsAlt, faHandsHelping, faEnvelope, faCheckCircle, faPalette, faAsterisk, faTasks, faExchangeAlt, faUmbrella)

const Hero = styled.div`
	background: linear-gradient(-225deg, #5bd5f3, #20bdff 24%, #20bdff 50%, #20bdff 75%, #b9e9f1);
	padding-top: 40px;
	display: grid;
	grid-template-rows: auto minmax(auto, auto);
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
`;

const Inner = styled.div`
	max-width: 1180px;
	margin: 0 auto;
	height: 100%;
	display: grid;
	align-content: center;
`;

const Top = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 60px;
	padding: 40px 0 60px;

	@media (min-width: 1200px) {
		grid-template-columns: minmax(164px, 1fr) minmax(auto, 580px);
		padding: 40px 0 80px;
	}
`;

const TopLeft = styled.div`
	text-align: center;
	min-height: 150px;

	@media (min-width: 1200px) {
		text-align: right;
		position: relative;
		min-height: auto;
	}
`;
const TopRight = styled.div`
	text-align: center;
	margin-top: 30px;
	padding: 0 20px;

	P {
		font-size: 1.15rem;
	}

	@media (min-width: 1200px) {
		text-align: left;
	}
`;

const FirstImg = styled(FontAwesomeIcon)`
	margin: 0 auto;

	@media (min-width: 1200px) {
		margin: 25px 12px;
	}
`;

const BottomDeploy = styled.div`
	background: rgb(27, 172, 229);
	padding: 20px;

	@media (min-width: 1200px) {
		height: 200px;
		z-index: 2;
	}
`;

const BottomInner = styled.div`
	align-items: flex-start;
	max-width: 1180px;
	margin: 0 auto;
	height: 100%;
	display: grid;
	align-content: center;
`;

const TextAndIconWrapper = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	display: grid;
	text-align: center;
	align-items: center;
	height: 100%;
	grid-template-columns: auto;
	grid-row-gap: 20px;

	@media (min-width: 1200px) {
		display: grid;
		height: 100%;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 100%;
		grid-gap: 30px;
		align-content: flex-start;
	}
`;

const TextAndIcon = styled.li`
	h2 {
		font-size: 1.1rem;
	}
	@media (min-width: 1200px) {
		display: grid;
		grid-template-columns: 60px 1fr;
		grid-column-gap: 20px;
		align-content: flex-start;
		text-align: center;
	}
`;

const LowerIcon = styled.div`
	@media (min-width: 1200px) {
		grid-row-start: 1;
		grid-row-end: span 2;
	}
`;

const StyledH1 = styled.h1`
	font-size: 1em;
`

function App() {

	return (
      <Layout>
			<SEO title="機動人才庫 Agile talent acquisition" />
			<Hero>
				<Inner>
					<Top>
						<TopLeft>
							<div style={{ opacity: 1 }}>
								<FirstImg className="contact-icons" icon="compress-arrows-alt" size="6x" />
							</div>
						</TopLeft>
						<TopRight>
							<StyledH1>機動人才令你業務迅速擴展，節省營運成本!</StyledH1>
							<p>中小企業人才庫<br />"核心 x 機動"的嶄新工作模式</p>
						</TopRight>
					</Top>
				</Inner>
				<BottomDeploy>
					<BottomInner>
						<TextAndIconWrapper>
							<TextAndIcon>
								<LowerIcon>
									<div style={{ opacity: 1 }}>
										<FirstImg className="contact-icons" icon="atom" size="3x" />
									</div>
								</LowerIcon>
								<h2>核心團隊</h2>
								<p>全職員工熟悉業務、營運方針，是推動公司發展的核心！機動人才可減輕核心團隊工作量，集中處理核心事務</p>
							</TextAndIcon>
							<TextAndIcon>
								<LowerIcon>
									<div style={{ opacity: 1 }}>
										<FirstImg className="contact-icons" icon="hands-helping" size="3x" />
									</div>
								</LowerIcon>
								<h2>機動人才</h2>
								<p>補充核心團隊所缺少的技能或新思維，按項目需求自選機動人才幫手，結合成無限技能組合，應付無限個項目</p>
							</TextAndIcon>
						</TextAndIconWrapper>
					</BottomInner>
				</BottomDeploy>
			</Hero>
			<AgileTalent />
			<HowItWork />
			<Benefit />
			{/* <JoinAgileTalent /> */}
			<AboutUs />
			<Partners />
      </Layout>
	);
};

export default App;
