import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.h2`
	text-align: center;
	line-height: 44px;
	display: block;
	font-size: 1.5rem;
	color: #333;
	font-family: Raleway;
	font-weight: 700;
	padding-top: 20px;

	@media (min-width: 1200px) {
		padding: 80px 0 40px;
	}
`;

export const StyledImg = styled.img`
	max-width: 100%;
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	/* margin-bottom: 1.45rem; */
`;

export const SectionTitle = ({ title }) => {
	return <StyledTitle>{title}</StyledTitle>;
};
