import React from 'react';
import LazyLoad from 'react-lazyload';

import {StyledImg} from "./styled";

const LazyImg = ({ height, once, offset, src, alt }) => {
	return <LazyLoad height={height} once={once} offset={offset} >
        <StyledImg 
            src={src}
            alt={alt}
        />
	</LazyLoad>
}

export default LazyImg;
