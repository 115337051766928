export const seoMetadata = {
    title: `Perhelp`,
    description: `請人唔一定要全職，機動人才靈活解決公司人手不足，配合核心員工提升公司策略，隨用隨付，立即聯絡我們免費開戶，建基於香港，搜羅人才`,
    // description: `Futurn of the work: Core team x Agile Talents = core x infinity skillset.`,
    author: `@Perry`,
    contact: {
      email: `info@perhelp.com`,
      fbMessenger: `https://www.messenger.com/t/perhelp`
    },
    social: {
      facebook: `https://www.facebook.com/perhelp`,
      instagram: `https://www.instagram.com/perhelp/`,
      telegram: `https://t.me/freelancerhk`
    },
    image: "/images/logo.png",
    url: "https://www.perhelp.com"
};
