import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
// import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.footer`
	flex: 1;
	padding-left: 2rem;
	padding-right: 3rem;
	padding-top: 0.25rem;
	/* background: deepskyblue; */
	background: #20BDFF;
	/* margin-top: 1.45rem; */
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 900px) {
		flex-direction: column;
	}
`;

const SocialMedia = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: 1.5rem;
	
	@media screen and (max-width: 900px) {
		justify-content: center;
	}
`;

const Contact = styled.div`
	display: flex;
	/* justify-content: center; */
	align-items: space-between;
	font-size: 1.5rem;
`;

const ContactIcon = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	text-decoration: none;
	padding: 5px;

	.contact-icons {
		color: #fff;
		margin-right: 0.3rem;
	}

	div {
		font-size: 0.8rem;
		font-weight: bold;
	}

	@media screen and (max-width: 900px) {
		margin: 5px;
	}
`;

const SocialIcon = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 3rem;
	height: 3rem;
	color: #fff;
	text-decoration: none;
	margin: 5px;
`;

const Information = styled.ul`
	display: flex;
	/* flex-direction: column; */
	justify-content: center;
	font-size: 0.8rem;
	list-style: none;
	margin: 10px;
	text-decoration: none;

	li {
		margin: 10px;
		text-decoration: none;
	}

	a {
		text-decoration: none;
		color: #fff;
	}

	@media screen and (max-width: 900px) {
		margin: 0;
	}
`;

const Footer = social => {
	return (
		<Container>
			{/* <Information>
				<li>
					<Link to="/terms-of-use"><p>Terms of use</p></Link>
				</li>
				<li>
					<Link to="/privacy-policy"><p>Privacy Policy</p></Link>
				</li>
			</Information> */}
			<Contact>
				<ContactIcon href={`mailto:${social.contact.email}`} target="_blank">
					<FontAwesomeIcon className="contact-icons" icon="envelope" />
					<div>info@perhelp.com</div>
				</ContactIcon>
				<ContactIcon rel="noopener" href={social.contact.fbMessenger} target="_blank">
					<FontAwesomeIcon className="contact-icons" icon={[ 'fab', 'facebook-messenger' ]} />
					<div>@perhelp</div>
				</ContactIcon>
			</Contact>
			<div>
				<SocialMedia>
					<div>
						<SocialIcon rel="noopener" href={social.social.facebook} aria-label="Facebook" target="_blank">
							<FontAwesomeIcon icon={[ 'fab', 'facebook' ]} />
						</SocialIcon>
					</div>
					<div>
						<SocialIcon rel="noopener" href={social.social.instagram} aria-label="Instagram" target="_blank">
							<FontAwesomeIcon icon={[ 'fab', 'instagram' ]} />
						</SocialIcon>
					</div>
					{/* <div>
						<SocialIcon rel="noopener" href={social.social.telegram} aria-label="Telegram" target="_blank">
							<FontAwesomeIcon icon={[ 'fab', 'telegram' ]} />
						</SocialIcon>
					</div> */}
				</SocialMedia>
				<div style={{ fontSize: '0.7rem', color: '#fff' }}>Perhelp© {new Date().getFullYear()}, All rights reserved.</div>
			</div>
		</Container>
	);
};

export default Footer;
